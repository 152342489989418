import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CALENDAR_ENTRY_STATUS from '../../utils/eventStatus'
import { DateTime, Settings } from 'luxon'
import PropTypes from 'prop-types'

const DAY_CALLBACK_TYPE = {
  TOGGLE_HOUR_SELECTION: 'DAY/TOGGLE_HOUR_SELECTION',
  TOGGLE_EVENT_SELECTION: 'DAY/TOGGLE_EVENT_SELECTION'
}

function Day({ day, selectedHours, callbackHandler }) {
  const [calendarEntries, setCalendarEntries] = useState([])

  const createCalendarEntries = useCallback(() => {
    const calendarEntries = []

    Settings.defaultZone = 'UTC'

    for (let hour = 0; hour < 24; hour++) {
      const hourDate = DateTime.fromISO(day.start).set({ hour: hour })
      const hourISO = hourDate.toISO()

      if (
        hour < DateTime.fromISO(day.start).hour ||
        hour > (DateTime.fromISO(day.end).hour || 24)
      ) {
        // outside of calendar
        calendarEntries.push({
          _id: hourISO,
          name: '',
          hours: [hour],
          status: CALENDAR_ENTRY_STATUS.OUTSIDE_CALENDAR,
          isSelected: false
        })
        continue
      }

      let addedEventEndHour = -1
      day.expand?.events
        .filter((event) => {
          return DateTime.fromISO(event.start).equals(hourDate)
        })
        .forEach((event) => {
          // push event
          const hours = []
          const eventEndHour = DateTime.fromISO(event.end).hour || 24
          for (var i = DateTime.fromISO(event.start).hour; i < eventEndHour; i++) {
            hours.push(i)
          }

          calendarEntries.push({
            ...event,
            status: CALENDAR_ENTRY_STATUS.EVENT,
            hours: hours
          })
          addedEventEndHour = eventEndHour
        })

      if (addedEventEndHour === -1) {
        // no event added -> push empty space
        const isSelected = selectedHours.find((selectedHour) => selectedHour.hour === hourISO)
        calendarEntries.push({
          _id: hourISO,
          name: '',
          hours: [hour],
          status: isSelected ? CALENDAR_ENTRY_STATUS.SELECTED : CALENDAR_ENTRY_STATUS.EMPTY
        })
      } else if (addedEventEndHour === 24) {
        // event added until 24h -> day is over, break
        break
      } else {
        // event added mid-day -> continue with next hour
        hour = addedEventEndHour - 1
      }
    }

    return calendarEntries
  }, [day.start, day.end, day?.expand?.events, selectedHours])

  useEffect(() => {
    setCalendarEntries(createCalendarEntries())
  }, [createCalendarEntries])

  const onEventPressed = (e, event) => {
    if (e.detail === 1) {
      // single click

      if (
        event.status === CALENDAR_ENTRY_STATUS.EMPTY ||
        event.status === CALENDAR_ENTRY_STATUS.SELECTED
      ) {
        callbackHandler(DAY_CALLBACK_TYPE.TOGGLE_HOUR_SELECTION, { hour: event._id, day: day.id })
      } else if (event.status === CALENDAR_ENTRY_STATUS.OUTSIDE_CALENDAR) {
        toast.dismiss()
        toast.info('Die ausgewählte Stunde liegt außerhalb der möglichen Gebetszeiten.')
      } else if (event.information) {
        toast.dismiss()
        toast.info(event.information)
      }
    } else if (e.detail === 2) {
      // double click

      if (event.status === CALENDAR_ENTRY_STATUS.EVENT) {
        callbackHandler(DAY_CALLBACK_TYPE.TOGGLE_EVENT_SELECTION, event)
      }
    }
  }

  const getStyleFromCategory = (event) => {
    if (event.status === CALENDAR_ENTRY_STATUS.EVENT) {
      return {
        backgroundColor: event.expand?.category.color
      }
    } else {
      return {}
    }
  }

  const getCssStyle = (event) => {
    const rows = event.hours.length
    return {
      ...getStyleFromCategory(event),
      gridRow: `span ${rows} / span ${rows}`
    }
  }

  const getClassStyleFromCategory = (event, hours) => {
    if (event.status === CALENDAR_ENTRY_STATUS.SELECTED) {
      return 'bg-blue-100'
    }
    if (event.status === CALENDAR_ENTRY_STATUS.OUTSIDE_CALENDAR) {
      return 'bg-white text-gray-300'
    } else if (event.status === CALENDAR_ENTRY_STATUS.EMPTY) {
      return 'bg-white'
    } else if (hours > 1) {
      // allow line-break for events that last multiple hours
      return 'whitespace-pre-line'
    } else {
      return ''
    }
  }

  return (
    <div className='flex flex-col w-full h-full min-w-full p-1 snap-start snap-always text-xs lg:min-w-0 print:min-w-0'>
      <div className='flex justify-center text-center p-2 font-bold bg-white print:border print:border-gray-300'>
        <span className='mr-1'>{DateTime.fromISO(day.start).weekdayShort + ','}</span>
        <span>{DateTime.fromISO(day.start).toFormat('dd.MM.')}</span>
      </div>

      <div className='flex-1 grid grid-cols-event justify-items-stretch mt-2 bg-white leading-3 cursor-pointer'>
        {calendarEntries &&
          calendarEntries.map((event) => {
            return [
              event.hours &&
                event.hours.map((hour, index) => [
                  <div
                    key={'0' + event._id + '0' + hour + '0' + index}
                    className={
                      'flex items-center p-1 select-none ' +
                      getClassStyleFromCategory(event, event.hours.length) +
                      ' print:border print:border-gray-300'
                    }
                    style={getStyleFromCategory(event)}
                    onClick={(e) => onEventPressed(e, event)}>
                    <span>{hour}</span>
                  </div>,
                  event.name !== '' && index === 0 && (
                    <div
                      key={'1' + event._id + '1' + hour + '1' + index}
                      className={
                        'flex items-center p-1 select-none truncate ... ' +
                        getClassStyleFromCategory(event, event.hours.length) +
                        ' print:border print:border-gray-300'
                      }
                      style={getCssStyle(event)}
                      onClick={(e) => onEventPressed(e, event)}>
                      {event.name}
                    </div>
                  ),
                  event.name === '' && (
                    <div
                      key={'2' + event._id + '2' + hour + '2' + index}
                      className={
                        'print:border print:border-gray-300 ' +
                        getClassStyleFromCategory(event, event.hours.length)
                      }
                      style={getCssStyle(event)}
                      onClick={(e) => onEventPressed(e, event)}></div>
                  )
                ])
            ]
          })}
      </div>
    </div>
  )
}

Day.propTypes = {
  day: PropTypes.object.isRequired,
  selectedHours: PropTypes.array.isRequired,
  callbackHandler: PropTypes.func.isRequired
}

export default Day
export { DAY_CALLBACK_TYPE }
