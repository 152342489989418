import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InsertEventForm, {
  INSERTEVENTFORM_CALLBACK_TYPE
} from '../components/calendar/InsertEventForm'
import {
  clearHourSelection,
  toggleHourSelection,
  setDisplayInsertEventForm,
  setDisplayDeleteEventForm,
  setSelectedEvent,
  createEvents,
  deleteEvent
} from '../features/eventSlice'
import { getCalendar } from '../features/calendarSlice'
import Day, { DAY_CALLBACK_TYPE } from '../components/calendar/Day'
import DeleteEventForm, {
  DELETEEVENTFORM_CALLBACK_TYPE
} from '../components/calendar/DeleteEventForm'
import { DateTime } from 'luxon'
import CalendarControlBar, {
  CALENDAR_CONTROLBAR_CALLBACK_TYPE
} from '../components/calendar/CalendarControlBar'
import LoadingOverlay from '../components/LoadingOverlay'
import Overlay from '../components/Overlay'
import Header from '../components/Header'
import { toast } from 'react-toastify'

function Calendar() {
  const dispatch = useDispatch()

  const { calendar, isCalendarLoading, errorCalendar } = useSelector((state) => state.calendar)
  const {
    selectedHours,
    selectedEvent,
    displayInsertEventForm,
    displayDeleteEventForm,
    isEventLoading,
    isEventSuccess,
    eventError
  } = useSelector((state) => state.event)
  const [isTimeOver, setIsTimeOver] = useState(false)
  const scrollElement = useRef(null)

  useEffect(() => {
    if (!errorCalendar) {
      dispatch(getCalendar())
    }

    setIsTimeOver(DateTime.now() > DateTime.fromISO(calendar.end))
  }, [errorCalendar])

  useEffect(() => {
    if (!isEventLoading) {
      if (isEventSuccess) {
        toast.success('Erfolgreich gespeichert.')
        dispatch(getCalendar())
      } else if (eventError) {
        toast.error(eventError)
      }
    }
  }, [isEventSuccess, eventError])

  const controlBarCallbackHandler = (type) => {
    switch (type) {
      case CALENDAR_CONTROLBAR_CALLBACK_TYPE.INSERT_BUTTON_PRESSED:
        dispatch(setDisplayDeleteEventForm(false))
        dispatch(setDisplayInsertEventForm(true))
        break
      case CALENDAR_CONTROLBAR_CALLBACK_TYPE.CLEAR_HOUR_SELECTION:
        dispatch(clearHourSelection())
        break
      default:
        console.error(`Unkonwn controlbar action ${type}`)
        break
    }
  }
  const dayCallbackHandler = (type, data) => {
    switch (type) {
      case DAY_CALLBACK_TYPE.TOGGLE_HOUR_SELECTION:
        dispatch(toggleHourSelection(data))
        break
      case DAY_CALLBACK_TYPE.TOGGLE_EVENT_SELECTION:
        dispatch(setSelectedEvent(data))
        break
      default:
        console.error(`Unkonwn day action ${type}`)
        break
    }
  }
  const insertEventFormCallbackHandler = (type, data) => {
    switch (type) {
      case INSERTEVENTFORM_CALLBACK_TYPE.INSERT_EVENTS:
        dispatch(createEvents({ events: data }))
        break
      case INSERTEVENTFORM_CALLBACK_TYPE.CLEAR_HOUR_SELECTION:
        dispatch(clearHourSelection())
        break
      case INSERTEVENTFORM_CALLBACK_TYPE.CLOSE:
        dispatch(setDisplayInsertEventForm(false))
        break
      default:
        console.error(`Unkonwn action ${type}`)
        break
    }
  }
  const deleteEventFormCallbackHandler = (type, data) => {
    switch (type) {
      case DELETEEVENTFORM_CALLBACK_TYPE.DELETE_EVENT:
        dispatch(deleteEvent({ calendarUrl: calendar.url, data: data }))
        break
      case DELETEEVENTFORM_CALLBACK_TYPE.CLOSE:
        dispatch(setDisplayDeleteEventForm(false))
        break
      default:
        console.error(`Unkonwn action ${type}`)
        break
    }
  }

  return (
    <div>
      <Header />

      <div className='md:flex' translate='no'>
        <main className='relative flex-1 h-[calc(100vh-7rem)] mt-20 print:100vh'>
          {errorCalendar ? (
            <Overlay>
              <h1>{errorCalendar}</h1>
              <span>Bitte lade die Seite erneut.</span>
            </Overlay>
          ) : !isTimeOver ? (
            <>
              <CalendarControlBar
                callbackHandler={controlBarCallbackHandler}
                scrollElement={scrollElement}
                selectedHours={selectedHours}
              />

              <section
                ref={scrollElement}
                className='relative w-full h-[calc(100%-4rem)] flex flex-row items-start mt-2 overflow-auto snap-mandatory snap-x print:h-full'>
                {calendar.expand && calendar.expand.days && calendar.expand.days.length > 0 && (
                  <>
                    {calendar.expand.days
                      .slice()
                      .sort((day1, day2) => day1.start > day2.start)
                      .map((day) => (
                        <Day
                          key={day.start}
                          day={day}
                          selectedHours={selectedHours}
                          callbackHandler={dayCallbackHandler}
                        />
                      ))}
                  </>
                )}
              </section>
            </>
          ) : (
            <Overlay>
              <h1>Die Gebetszeit ist leider schon vorbei.</h1>
              <h4>Danke für dein Gebete!</h4>
              <h4>Wir freuen uns schon auf nächstes Jahr.</h4>
            </Overlay>
          )}

          {isCalendarLoading && <LoadingOverlay overlayText='Kalender wird geladen...' />}
        </main>

        {displayInsertEventForm && (
          <InsertEventForm
            isLoading={isEventLoading}
            selectedHours={selectedHours}
            categories={calendar.expand.categories.filter((c) => c.selectable)}
            callbackHandler={insertEventFormCallbackHandler}
          />
        )}

        {displayDeleteEventForm && (
          <DeleteEventForm
            isLoading={isEventLoading}
            event={selectedEvent}
            callbackHandler={deleteEventFormCallbackHandler}
          />
        )}
      </div>
    </div>
  )
}

export default Calendar
