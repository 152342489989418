import { FiX } from 'react-icons/fi'
import PropTypes from 'prop-types'

function Form({ onSubmit, onClose, children }) {
  return (
    <div className='h-[calc(100vh-7rem)] bg-gray-100 m-4 ml-0 mt-20 p-4'>
      <div className='z-20 fixed bottom-24 top-24 left-6 right-6 bg-gray-100 lg:relative lg:top-0 lg:bottom-0 lg:left-0 lg:right-0 lg:h-full '>
        <div className='absolute right-0'>
          <button
            className='m-2 p-2 z-30 leading-3 rounded bg-gray-300 hover:bg-gray-400'
            onClick={onClose}>
            <FiX />
          </button>
        </div>

        <form
          onSubmit={onSubmit}
          className='max-h-full flex flex-col items-center text-center overflow-y-auto overflow-x-hidden gap-6 p-8'>
          {children}
        </form>
      </div>
      <div
        className='z-10 fixed bottom-0 top-0 left-0 right-0 bg-black opacity-70 lg:hidden'
        onClick={onClose}></div>
    </div>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default Form
