import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiService from './apiService'
import handleAsyncApiError from '../utils/sliceUtils'

const initialState = {
  calendar: {},
  isCalendarLoading: false,
  calendarError: ''
}

// get calendar
export const getCalendar = createAsyncThunk('calendars/get', async (thunkAPI) => {
  try {
    const calendarUrl = window.location.pathname.split('/')[1]

    return await apiService.getCalendar(calendarUrl)
  } catch (error) {
    return handleAsyncApiError(thunkAPI, error)
  }
})

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCalendar.pending, (state) => {
        state.isCalendarLoading = true
      })
      .addCase(getCalendar.fulfilled, (state, action) => {
        state.isCalendarLoading = false
        state.errorCalendar = ''
        state.calendar = action.payload
      })
      .addCase(getCalendar.rejected, (state, action) => {
        state.isCalendarLoading = false
        state.errorCalendar = action.payload
      })
  }
})

export const { reset } = calendarSlice.actions
export default calendarSlice.reducer
