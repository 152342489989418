import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import Calendar from './pages/Calendar'
import Impressum from './pages/Impressum'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Navigate to='/2024' replace />} />
          <Route path='/:id' element={<Dashboard />} />
          <Route path='/:id/kalender' element={<Calendar />} />
          <Route path='/impressum' element={<Impressum />} />
        </Routes>
      </Router>

      <ToastContainer hideProgressBar={true} />
    </>
  )
}

export default App
