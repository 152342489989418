import { configureStore } from '@reduxjs/toolkit'
import eventReducer from '../features/eventSlice'
import calendarReducer from '../features/calendarSlice'

export const store = configureStore({
  reducer: {
    event: eventReducer,
    calendar: calendarReducer
  }
})
