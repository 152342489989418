import { useRef } from 'react'
import PropTypes from 'prop-types'

function Tooltip({ text, children, className }) {
  const tooltipRef = useRef()

  const showToolTip = () => {
    tooltipRef.current.style.display = 'block'
  }
  const hideToolTip = () => {
    tooltipRef.current.style.display = 'none'
  }
  const onClick = (e) => {
    e.preventDefault()

    if (tooltipRef.current.style.display === 'none') {
      showToolTip()
      setTimeout(() => hideToolTip(), 3000)
    } else {
      hideToolTip()
    }
  }

  return (
    <div
      className={'relative flex ' + className}
      onClick={onClick}
      onMouseEnter={showToolTip}
      onMouseLeave={hideToolTip}>
      <div
        className='absolute w-40 hidden -left-28 bottom-12 text-sm leading-4 bg-black text-white p-4 rounded transition-all duration-150'
        ref={tooltipRef}>
        <div className='absolute h-3 w-3 right-6 -bottom-1 rotate-45 bg-black'></div>
        {text}
      </div>

      {children}
    </div>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Tooltip
