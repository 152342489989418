import { PropTypes } from 'prop-types'
import Overlay from './Overlay'
import Spinner from './Spinner'

function LoadingOverlay({ overlayText }) {
  return (
    <Overlay>
      <Spinner />
      <p>{overlayText}</p>
    </Overlay>
  )
}

LoadingOverlay.propTypes = {
  overlayText: PropTypes.string.isRequired
}

export default LoadingOverlay
