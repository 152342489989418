import { PropTypes } from 'prop-types'

function Overlay({ children }) {
  return (
    <div className='absolute top-0 left-0 w-[100%] h-[100%] flex flex-col items-center justify-center  bg-white-overlay'>
      {children}
    </div>
  )
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired
}

export default Overlay
