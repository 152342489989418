import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'

function Title({ title }) {
  return <span className='ml-3'>{title}</span>
}

Title.propTypes = {
  title: PropTypes.string.isRequired
}

function HeaderNavLink({ to, href, mail, title, icon }) {
  const navLinkClass = 'flex p-4 items-center rounded hover:bg-gray-400 lg:p-2'

  return (
    <>
      {to && (
        <Link to={to} className={navLinkClass}>
          {icon}
          <Title title={title} />
        </Link>
      )}
      {href && (
        <a className={navLinkClass} href={href} target='_blank' rel='noreferrer'>
          {icon}
          <Title title={title} />
        </a>
      )}
      {mail && (
        <a className={navLinkClass} href={mail}>
          {icon}
          <Title title={title} />
        </a>
      )}
    </>
  )
}

HeaderNavLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  mail: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired
}

export default HeaderNavLink
