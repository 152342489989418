import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'

function Title({ title }) {
  return <span>{title}</span>
}

Title.propTypes = {
  title: PropTypes.string.isRequired
}

function DashboardLinkButton({ to, href, mail, title, icon, img }) {
  var buttonClass = 'flex flex-col p-4 items-center rounded bg-gray-300 hover:bg-gray-400'
  icon && (buttonClass += ' px-8 first-child:h-12 first-child:w-12 first-child:m-4')
  img && (buttonClass += ' first-child:h-48 first-child:mb-2')

  return (
    <>
      {to && (
        <Link to={to} className={buttonClass}>
          {icon && icon}
          {img && img}
          <Title title={title} />
        </Link>
      )}
      {href && (
        <a className={buttonClass} href={href} target='_blank' rel='noreferrer'>
          {icon && icon}
          {img && img} <Title title={title} />
        </a>
      )}
      {mail && (
        <a className={buttonClass} href={mail}>
          {icon && icon}
          {img && img} <Title title={title} />
        </a>
      )}
    </>
  )
}

DashboardLinkButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  mail: PropTypes.string,
  icon: PropTypes.node,
  img: PropTypes.node,
  title: PropTypes.string.isRequired
}

export default DashboardLinkButton
