import PropTypes from 'prop-types'

function SelectedEventsList({ events, className }) {
  const printEvent = (event) => {
    var name = event.name ? `Name: ${event.name} \n` : ''
    var category = event.category ? `Kategorie: ${event.category.name} \n` : ''

    var fromDate = event.start.weekdayShort + event.start.toFormat(' (dd.MM.),')
    var fromHour = event.start.hour
    var until = event.end.hour || '24'

    if (
      event.start.day !== event.end.day &&
      !event.start.set({ days: event.start.day + 1, hour: 0 }).equals(event.end)
    ) {
      until = event.end.weekdayShort + event.end.toFormat(' (dd.MM.), ') + until
    }

    return `${name} ${category} ${fromDate} von ${fromHour} Uhr bis ${until} Uhr`
  }

  return (
    <div className={className}>
      <div className='flex flex-wrap text-center justify-center items-center gap-x-8 gap-y-2 whitespace-pre-line'>
        <div>Ausgewählt:</div>
        {events.length === 1 ? (
          <div className='text-left'>{printEvent(events.at(0))}</div>
        ) : (
          <ul className='list-disc text-left'>
            {events.length > 0 &&
              events.map((event) => <li key={event.start}>{printEvent(event)}</li>)}
          </ul>
        )}
      </div>
    </div>
  )
}

SelectedEventsList.propTypes = {
  events: PropTypes.array.isRequired,
  className: PropTypes.string
}

export default SelectedEventsList
