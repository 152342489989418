import { useState } from 'react'
import Form from '../Form'
import SelectedEventsList from './SelectedEventsList'
import TextInput from '../TextInput'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import LoadingOverlay from '../LoadingOverlay'

const DELETEEVENTFORM_CALLBACK_TYPE = {
  DELETE_EVENT: 'DELETEEVENTFORM/DELETEEVENT',
  CLOSE: 'DELETEEVENTFORM/CLOSE'
}

function DeleteEventForm({ isLoading, event, callbackHandler }) {
  const [mail, setMail] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()

    setMail('')

    callbackHandler(DELETEEVENTFORM_CALLBACK_TYPE.DELETE_EVENT, {
      event,
      mail
    })
  }

  const convertEventDates = (event) => {
    return {
      start: DateTime.fromISO(event.start),
      end: DateTime.fromISO(event.end),
      _id: event._id,
      category: event.expand.category,
      name: event.name
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      onClose={() => callbackHandler(DELETEEVENTFORM_CALLBACK_TYPE.CLOSE)}
      title='Eintrag löschen'>
      <h2>Eintrag löschen</h2>
      <SelectedEventsList events={[convertEventDates(event)]} />

      <hr />

      <TextInput
        type='email'
        name='mail'
        placeholder='E-Mail'
        required={true}
        value={mail}
        onChange={(e) => setMail(e.target.value)}
        information='Gebe die E-Mail-Adresse ein, mit der du dich eintragen hast.'
        tabIndex={1}
      />

      <button
        type='submit'
        className='flex p-2 rounded bg-green-300 hover:bg-green-400'
        tabIndex={2}>
        Eintrag löschen
      </button>

      {isLoading && <LoadingOverlay overlayText='Eintrag wird gelöscht...' />}
    </Form>
  )
}

DeleteEventForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  callbackHandler: PropTypes.func.isRequired
}

export default DeleteEventForm
export { DELETEEVENTFORM_CALLBACK_TYPE }
