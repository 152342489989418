import { useEffect, useCallback, useState } from 'react'
import { FiInfo } from 'react-icons/fi'
import Form from '../Form'
import SelectedEventsList from './SelectedEventsList'
import TextInput from '../TextInput'
import TextArea from '../TextArea'
import Tooltip from '../Tooltip'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import LoadingOverlay from '../LoadingOverlay'

const INSERTEVENTFORM_CALLBACK_TYPE = {
  INSERT_EVENTS: 'INSERTEVENTFORM/INSERTEVENTS',
  CLEAR_HOUR_SELECTION: 'INSERTEVENTFORM/CLEAR_HOUR_SELECTION',
  CLOSE: 'INSERTEVENTFORM/CLOSE'
}

function InsertEventForm({ isLoading, selectedHours, categories, callbackHandler }) {
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [information, setInformation] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [summarizedSelectedHours, setSummarizedSelectedHours] = useState([])

  const createSummarizedSelectedHoursArray = useCallback(() => {
    const summarizedSelectedHoursArray = []

    var selectedHoursSorted = selectedHours
      .slice()
      .sort((a, b) => (DateTime.fromISO(a.hour) > DateTime.fromISO(b.hour) ? 1 : -1))

    selectedHoursSorted.forEach((selectedHour) => {
      const date = DateTime.fromISO(selectedHour.hour)
      if (
        summarizedSelectedHoursArray.length > 0 &&
        summarizedSelectedHoursArray.at(-1).end.equals(date) &&
        summarizedSelectedHoursArray.at(-1).day === selectedHour.day
      ) {
        summarizedSelectedHoursArray.at(-1).end = date.plus({ hours: 1 })
      } else {
        const endHour = date.plus({ hours: 1 })
        summarizedSelectedHoursArray.push({ start: date, end: endHour, day: selectedHour.day })
      }
    })

    return summarizedSelectedHoursArray
  }, [selectedHours])

  useEffect(() => {
    setSummarizedSelectedHours(createSummarizedSelectedHoursArray())

    // activate first category by default
    setSelectedCategory(categories.at().id)
  }, [setSummarizedSelectedHours, createSummarizedSelectedHoursArray, categories])

  const onSubmit = (e) => {
    e.preventDefault()

    e.currentTarget.disabled = true

    const data = []

    summarizedSelectedHours.forEach((summarizedSelectedHour) => {
      data.push({
        name: name,
        mail: mail,
        information: information,
        category: categories.find((category) => category.id === selectedCategory),
        start: summarizedSelectedHour.start,
        end: summarizedSelectedHour.end,
        day: summarizedSelectedHour.day
      })
    })

    callbackHandler(INSERTEVENTFORM_CALLBACK_TYPE.INSERT_EVENTS, data)
  }

  return (
    <Form onSubmit={onSubmit} onClose={() => callbackHandler(INSERTEVENTFORM_CALLBACK_TYPE.CLOSE)}>
      <h2>Eintragen</h2>
      <SelectedEventsList events={summarizedSelectedHours} />

      <hr />

      <div className='flex flex-col gap-2 max-w-full'>
        <TextInput
          type='text'
          name='name'
          placeholder='Name'
          required={true}
          value={name}
          onChange={(e) => setName(e.target.value)}
          information='Dein Name wird öffentlich im Kalender angezeigt.'
          tabIndex={1}
        />
        <TextInput
          type='email'
          name='mail'
          placeholder='E-Mail'
          required={true}
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          information='Mit der E-Mail-Adresse kannst du deine Einträge später bei Bedarf löschen. Dafür den Eintrag im Kalender doppel-klicken.'
          tabIndex={2}
        />
      </div>

      <div className='flex flex-wrap gap-4 justify-center items-center'>
        <div>Kategorie:</div>
        <div className='flex flex-col text-left'>
          {categories.map((category) => (
            <label key={category.id}>
              <input
                type='radio'
                value={category.id}
                name='category'
                checked={selectedCategory === category.id}
                className='mr-1'
                onChange={() => setSelectedCategory(category.id)}
                tabIndex={3}
              />
              {category.name}
            </label>
          ))}
        </div>
        <Tooltip text='Die Kategorie bestimmt die Farbe, mit der dein Termin angezeigt wird.'>
          {/* <button varient="gradient"> */}
          <button>
            <FiInfo className='w-5 h-5 text-gray-400' />
          </button>
        </Tooltip>
      </div>

      {categories.filter((c) => c.id === selectedCategory && c.hasInformation).length > 0 && (
        <TextArea
          name='information'
          placeholder='Information'
          required={true}
          maxLength={100}
          value={information}
          onChange={(e) => setInformation(e.target.value)}
          information='Gebe an, was du anbietest.'
          tabIndex={4}
        />
      )}

      <button type='submit' className='p-2 rounded bg-green-300 hover:bg-green-400' tabIndex={5}>
        Eintragen
      </button>

      {isLoading && <LoadingOverlay overlayText='Speichern...' />}
    </Form>
  )
}

InsertEventForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectedHours: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  callbackHandler: PropTypes.func.isRequired
}

export default InsertEventForm
export { INSERTEVENTFORM_CALLBACK_TYPE }
