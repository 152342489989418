import { FiInfo } from 'react-icons/fi'
import Tooltip from './Tooltip'
import PropTypes from 'prop-types'

function TextInput({
  className,
  type,
  name,
  placeholder,
  required,
  maxLength,
  value,
  onChange,
  information,
  tabIndex
}) {
  return (
    <div className={'max-w-full ' + className}>
      <div className='flex flex-wrap justify-center gap-2'>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          required={required}
          value={value}
          onChange={onChange}
          className='p-2 max-w-full'
          tabIndex={tabIndex}
        />

        <Tooltip text={information}>
          {/* <button varient="gradient"> */}
          <button>
            <FiInfo className='w-5 h-5 text-gray-400' />
          </button>
        </Tooltip>
      </div>
    </div>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  information: PropTypes.string,
  tabIndex: PropTypes.number
}

export default TextInput
