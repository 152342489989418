import { FiInfo } from 'react-icons/fi'
import Tooltip from './Tooltip'
import PropTypes from 'prop-types'

function TextArea({
  className,
  name,
  placeholder,
  required,
  maxLength,
  value,
  onChange,
  information
}) {
  return (
    <div className={'max-w-full ' + className}>
      <div className='flex flex-wrap justify-center gap-2'>
        <textarea
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          required={required}
          value={value}
          onChange={onChange}
          className='p-2 max-w-full min-h-[2.5rem]'
        />
        {information !== '' && (
          <Tooltip text={information}>
            {/* <button varient="gradient"> */}
            <button>
              <FiInfo className='w-5 h-5 text-gray-400' />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

TextArea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  information: PropTypes.string
}

export default TextArea
