import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

const CALENDAR_CONTROLBAR_CALLBACK_TYPE = {
  INSERT_BUTTON_PRESSED: 'CALENDAR_CONTROLBAR/INSERT_BUTTON_PRESSED',
  CLEAR_HOUR_SELECTION: 'CALENDAR_CONTROLBAR/CLEAR_HOUR_SELECTION'
}

function CalendarControlBar({ callbackHandler, scrollElement, selectedHours }) {
  const SCROLL_DIRECTION = {
    LEFT: 'left',
    RIGHT: 'right'
  }
  const SCROLL_TYPE = {
    NEXT_PAGE: 'next_page',
    UNTIL_EDGE: 'until_edge'
  }

  const onScrollButtonClicked = (scrollDirection, scrollType) => {
    if (scrollType === SCROLL_TYPE.UNTIL_EDGE) {
      scrollElement.current.scrollTo({
        left: scrollDirection === SCROLL_DIRECTION.LEFT ? 0 : 99999,
        behavior: 'smooth'
      })
    } else {
      scrollElement.current.scrollBy({
        left: scrollDirection === SCROLL_DIRECTION.LEFT ? -window.innerWidth : window.innerWidth,
        behavior: 'smooth'
      })
    }
  }

  const onInsertButtonPressed = () => {
    if (selectedHours.length > 0) {
      callbackHandler(CALENDAR_CONTROLBAR_CALLBACK_TYPE.INSERT_BUTTON_PRESSED)
    } else {
      // display hint that events can be selected
      toast.dismiss()
      toast.info(
        'Wähle die Stunden aus, für die du dich eintragen möchtest.\n(Mehrfachauswahl möglich)'
      )
    }
  }

  return (
    <div className='flex items-center h-12 print:hidden'>
      <div className='lg:hidden'>
        <button
          className='ml-2 p-2 leading-3 rounded bg-gray-300 hover:bg-gray-400'
          onClick={() => onScrollButtonClicked(SCROLL_DIRECTION.LEFT, SCROLL_TYPE.UNTIL_EDGE)}>
          <FiChevronsLeft />
        </button>
        <button
          className='ml-2 p-2 leading-3 rounded bg-gray-300 hover:bg-gray-400'
          onClick={() => onScrollButtonClicked(SCROLL_DIRECTION.LEFT, SCROLL_TYPE.NEXT_PAGE)}>
          <FiChevronLeft />
        </button>
      </div>

      <div className='flex-1 text-center'>
        <button
          className='ml-2 p-2 rounded bg-green-300 hover:bg-green-400'
          onClick={onInsertButtonPressed}>
          Eintragen
        </button>
        {selectedHours.length > 0 && (
          <button
            className='ml-2 p-2 rounded bg-orange-300 hover:bg-orange-400'
            onClick={() => callbackHandler(CALENDAR_CONTROLBAR_CALLBACK_TYPE.CLEAR_HOUR_SELECTION)}>
            Auswahl löschen
          </button>
        )}
      </div>

      <div className='lg:hidden'>
        <button
          className='ml-2 p-2 leading-3 rounded bg-gray-300 hover:bg-gray-400'
          onClick={() => onScrollButtonClicked(SCROLL_DIRECTION.RIGHT, SCROLL_TYPE.NEXT_PAGE)}>
          <FiChevronRight />
        </button>
        <button
          className='ml-2 p-2 leading-3 rounded bg-gray-300 hover:bg-gray-400'
          onClick={() => onScrollButtonClicked(SCROLL_DIRECTION.RIGHT, SCROLL_TYPE.UNTIL_EDGE)}>
          <FiChevronsRight />
        </button>
      </div>
    </div>
  )
}

CalendarControlBar.propTypes = {
  callbackHandler: PropTypes.func.isRequired,
  scrollElement: PropTypes.object.isRequired,
  selectedHours: PropTypes.array.isRequired
}

export default CalendarControlBar
export { CALENDAR_CONTROLBAR_CALLBACK_TYPE }
