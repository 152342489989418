import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from './apiService'
import handleAsyncApiError from '../utils/sliceUtils'

const initialState = {
  selectedHours: [], // for inserting
  selectedEvent: null, // for deleting
  displayInsertEventForm: false,
  displayDeleteEventForm: false,
  isEventLoading: false,
  isEventSuccess: false,
  eventError: ''
}

// create new events
export const createEvents = createAsyncThunk('events/create', async ({ events }, thunkAPI) => {
  try {
    return await apiService.createEvents(events)
  } catch (error) {
    return handleAsyncApiError(thunkAPI, error)
  }
})

// delete event
export const deleteEvent = createAsyncThunk('events/delete', async ({ data }, thunkAPI) => {
  try {
    const eventId = data.event.id
    const mail = data.mail
    return await apiService.deleteEvent(eventId, mail)
  } catch (error) {
    return handleAsyncApiError(thunkAPI, error)
  }
})

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    reset: () => initialState,
    clearHourSelection: (state) => {
      state.selectedHours = []
    },
    toggleHourSelection: (state, data) => {
      const { hour, day } = data.payload
      if (state.selectedHours.find((selectedHour) => selectedHour.hour === hour)) {
        // de-select hour
        state.selectedHours = state.selectedHours.filter(
          (selectedHour) => selectedHour.hour !== hour
        )

        if (state.selectedHours.length <= 0) {
          state.displayInsertEventForm = false
        }
      } else {
        // select hour
        state.selectedHours.push({ hour, day })
      }
    },
    setDisplayInsertEventForm: (state, data) => {
      state.displayInsertEventForm = data.payload
    },
    setDisplayDeleteEventForm: (state, data) => {
      state.displayDeleteEventForm = data.payload
    },
    setSelectedEvent: (state, data) => {
      state.selectedEvent = data.payload
      state.displayDeleteEventForm = true
      state.displayInsertEventForm = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEvents.pending, (state) => {
        state.isEventLoading = true
        state.isEventSuccess = false
        state.eventError = ''
      })
      .addCase(createEvents.fulfilled, (state) => {
        state.isEventLoading = false
        state.isEventSuccess = true
        state.selectedHours = []
        state.displayInsertEventForm = false
      })
      .addCase(createEvents.rejected, (state, action) => {
        state.isEventLoading = false
        state.isEventSuccess = false
        state.eventError = 'Eintragen fehlgeschlagen:\n' + action.payload
      })

      .addCase(deleteEvent.pending, (state) => {
        state.isEventLoading = true
        state.isEventSuccess = false
        state.eventError = ''
      })
      .addCase(deleteEvent.fulfilled, (state) => {
        state.isEventLoading = false
        state.isEventSuccess = true
        state.selectedHours = []
        state.displayDeleteEventForm = false
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.isEventLoading = false
        state.isEventSuccess = false
        state.eventError = 'Löschen fehlgeschlagen:\n' + action.payload
      })
  }
})

export const {
  reset,
  toggleHourSelection,
  clearHourSelection,
  setDisplayInsertEventForm,
  setDisplayDeleteEventForm,
  setSelectedEvent,
  onCreateEvents,
  onDeleteEvent
} = eventSlice.actions

export default eventSlice.reducer
